
import { Options, Vue } from 'vue-class-component'
import newMember from '@/components/Memberportal/NewClubMember/index.vue'

@Options({
  components: {
    newMember
  }
})
export default class NewMember extends Vue {}
