import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class History extends APIBaseFunctions {
  static HistoryDataService = class {
    private static cachedHistoryDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = History.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedHistoryDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/medlemshistoriks?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/medlemshistoriks?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = History.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedHistoryDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/medlemshistoriks?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/medlemshistoriks?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/medlemshistoriks/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/medlemshistoriks/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/medlemshistoriks', data)
    }

    static update (id: string, data: any) {
      return http.put(`/medlemshistoriks/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/medlemshistoriks/${id}`)
    }

    static deleteAll () {
      return http.delete('/medlemshistoriks')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = History.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedHistoryDataSliceObj }, dataSliceObj)
      searchTerm = searchTerm.trim()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/medlemshistoriks?_where[_or][0][medlemshistorik_handling_contains]=${searchTerm}&_where[_or][1][klubber_id.klubber_klubnavn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static ValidatedPlayersHistoriesDataService = class {
    private static cachedValidatedPlayersHistoriesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static create (data: any) {
      return http.post('/validated-players-histories', data)
    }

    // static getAll (dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    //   const currentDataSliceObj = History.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedValidatedPlayersHistoriesDataSliceObj }, dataSliceObj)
    //   return http.get(`/validated-players-histories?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    // }

    // static getCount () {
    //   return http.get('/validated-players-histories/count')
    // }

    static async get () {
      return http.get('/validated-players-histories')
    }

    static getAll (dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = History.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedValidatedPlayersHistoriesDataSliceObj }, dataSliceObj)
      return http.get(`/validated-players-histories?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static getCount (extraParameter = '') {
      return http.get(`/validated-players-histories/count?${extraParameter}`)
    }

    static update (id: any, data: any) {
      return http.put(`/validated-players-histories/${id}`, data)
    }
  }

  createPlayerHistory (data: any) {
    return http.post('/validated-players-histories', data)
  }

  async getValidatePlayerHistory () {
    return http.get('/validated-players-histories')
  }
}

export default new History()
