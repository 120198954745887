import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class ByPostDataService extends APIBaseFunctions {
  private cachedByPostDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ByPostDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedByPostDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/by-posts?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/by-posts?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/by-posts/${id}`)
  }

  getCount () {
    return http.get('/by-posts/count')
  }

  create (data: any) {
    return http.post('/by-posts', data)
  }

  update (id: string, data: any) {
    return http.put(`/by-posts/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/by-posts/${id}`)
  }

  deleteAll () {
    return http.delete('/by-posts')
  }

  findByPostalnumber (locationPostalnumber: number, sortingOrder = '', limitResultLength = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    // let limitResultLengthParameter = ''
    const currentDataSliceObj = ByPostDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedByPostDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }
    if (limitResultLength.length > 0) {
      // limitResultLengthParameter = `&_limit=${limitResultLength}`
      currentDataSliceObj.limit = Number(limitResultLength)
    }

    return http.get(`/by-posts?bypost_postnummer=${locationPostalnumber}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findByCity (locationCity: string, sortingOrder = '', limitResultLength = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    // let limitResultLengthParameter = ''
    const currentDataSliceObj = ByPostDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedByPostDataSliceObj }, dataSliceObj)
    locationCity = locationCity.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }
    if (limitResultLength.length > 0) {
      // limitResultLengthParameter = `&_limit=${limitResultLength}`
      currentDataSliceObj.limit = Number(limitResultLength)
    }

    return http.get(`/by-posts?bypost_by_contains=${locationCity}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }
}

export default new ByPostDataService()
